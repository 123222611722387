import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Pill,
  PillColor,
  PhosphorIcon,
  ToastKind,
  ToastManager,
  LoadingSpinner,
  ButtonKind,
} from 'design-system/components';
import { determineInitials } from 'design-system/utils';
import styles from './eligibility-status-table.module.scss';
import { useMemo, useState } from 'react';
import useFetcher from 'services/api/useFetcher';
import { useApp } from 'context/AppContext';
import {
  PolicyType,
  EligibilityContextType,
  useEligibilityContext,
} from './EligibilityContext';

type ToastData = {
  title: string;
  kind: string;
  message: string;
};

type HandleResendClick = (
  contractId: number,
  setLoading: (loading: boolean) => void
) => void;

type PolicyTableRowProps = {
  policy: PolicyType;
  handleResendClick: HandleResendClick;
  hasResendableColumn: boolean;
};

function DocumentsPageEligibilityStatusTable() {
  const fetcher = useFetcher();
  const [toastData, setToastData] = useState<ToastData[]>([]);
  const { containedPolicies } =
    useEligibilityContext() as EligibilityContextType;
  const { user } = useApp();
  const resendContractsFlagActive = user.hasFF('resend_pillar_agreement');

  const anyResendable = useMemo(() => {
    return containedPolicies.some((pol) => !pol.isEnrolled && pol.contractId);
  }, [containedPolicies]);

  const handleResendClick: HandleResendClick = async (
    contractId,
    setLoading
  ) => {
    // PDCT-785 React batching may disrupt this.
    setLoading(true);
    try {
      const res = await fetcher(
        `/api/v4/policy_signed_contracts/${contractId}/resend_policy_signature_request`,
        { method: 'PUT' }
      );
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          title: 'Success',
          kind: ToastKind.Success,
          message: `Signature request has been resent${
            res?.email ? ` to ${res?.email}` : ''
          }.`,
        },
      ]);
    } catch {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          title: 'Error',
          kind: ToastKind.Error,
          message: 'Request failed. Please try again or contact support.',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const hasResendableColumn = anyResendable && resendContractsFlagActive;

  return (
    <div
      className={
        anyResendable && resendContractsFlagActive
          ? styles.table3
          : styles.table2
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Contract</TableHeadCell>
            <TableHeadCell>Signed</TableHeadCell>
            {hasResendableColumn && (
              <TableHeadCell>
                <span className="sr-only">Action</span>
              </TableHeadCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {containedPolicies.map((policy: PolicyType) => {
            return (
              <PolicyTableRow
                hasResendableColumn={hasResendableColumn}
                handleResendClick={handleResendClick}
                policy={policy}
              />
            );
          })}
        </TableBody>
      </Table>
      <ToastManager data={toastData} />
    </div>
  );
}

export default DocumentsPageEligibilityStatusTable;

const PolicyTableRow = ({
  policy,
  handleResendClick,
  hasResendableColumn,
}: PolicyTableRowProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <TableRow key={policy.id}>
      <TableBodyCell>
        <div className={styles['avatar-cell']}>
          <Avatar
            initials={determineInitials(policy.name)}
            src={policy.imagePath}
          />
          {policy.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        {policy.isEnrolled ? (
          <Pill color={PillColor.Success}>
            <PhosphorIcon size={12} iconName="PencilLine" />
            Signed
          </Pill>
        ) : (
          <Pill color={PillColor.ErrorColor}>
            <PhosphorIcon size={12} iconName="X" />
            Not signed
          </Pill>
        )}
      </TableBodyCell>
      {hasResendableColumn && (
        <TableBodyCell>
          {!policy.isEnrolled && policy.contractId && (
            <Button
              kind={ButtonKind.Alternate}
              onClick={() => handleResendClick(policy.contractId, setLoading)}
            >
              {loading ? (
                <LoadingSpinner size={20} />
              ) : (
                <div className={styles['button-wrapper']}>
                  Resend
                  <PhosphorIcon iconName="PaperPlaneTilt" size={20} />
                </div>
              )}
            </Button>
          )}
        </TableBodyCell>
      )}
    </TableRow>
  );
};
