import React, { lazy, Suspense } from 'react';
import NotchedCircle from './NotchedCircle.js';
import styles from './phosphor-icon.module.scss';
import { PhosphorIconWeight } from './constants.js';

interface PhosphorIconProps {
  iconName: string;
  size?: number | string;
  className?: string;
  color?: string;
  weight?: keyof typeof PhosphorIconWeight;
  title?: string;
  style?: object;
  forceLoading?: boolean;
}

// Load icon as needed rather than load entire library.
const loadIcon = async (iconName: string) => {
  const module = await import('@phosphor-icons/react');
  let name = iconName;
  if (!(iconName in module)) {
    console.error(`Icon ${iconName} not found in @phosphor-icons/react`);
    name = 'CircleDashed';
  }
  return {
    default: module[
      name as keyof typeof module
    ] as unknown as React.ComponentType<any>,
  };
};

const PhosphorIcon = ({
  iconName,
  size = 18,
  forceLoading = false,
  ...props
}: PhosphorIconProps) => {
  const LazyIcon = lazy(() => loadIcon(iconName));
  const loadingIcon = (
    <span className={styles.loading}>
      <NotchedCircle size={size} />
    </span>
  );

  return (
    <Suspense fallback={loadingIcon}>
      {forceLoading && loadingIcon}
      {LazyIcon && !forceLoading && <LazyIcon {...props} size={size} />}
    </Suspense>
  );
};

export default PhosphorIcon;
