import * as Sentry from '@sentry/react';
import { v4 as uuid } from 'uuid';

import { APIBase } from 'design-system/types';
import { PUBLIC_TOKEN } from 'constants/api';
import globalToken from 'utils/token';
import { stringify, parse } from 'utils/querystring';
import { sentry } from 'utils/sentry';
import FetchError from './errors/FetchError';

const UNAUTHENTICATED_MSG =
  'It appears you are not logged in. Please sign in to view this page.';
const UNAUTHORIZED_MSG = 'You are not authorized to perform this action';
export const DEFAULT_ERROR_MSG =
  'Something went wrong, please try again or contact support@noviconnect.com';

export function makeFetcher(fetcherOptions = {}) {
  const { isPublicApi = false } = fetcherOptions;
  let currentAbortController = null;

  async function fetcher(resource, options = {}) {
    const {
      headers = {},
      params = {},
      retryTimes,
      enabled = true,
      preventAbort = false,
      apiBase = APIBase.RAILS,
    } = options;
    if (!enabled) {
      return;
    }

    if (currentAbortController && !preventAbort) {
      currentAbortController.abort('Duplicate Fetch');
    }
    if (!preventAbort) {
      currentAbortController = new AbortController();
    }
    const signal = currentAbortController?.signal;
    const transactionId = uuid();

    Sentry.configureScope((scope) =>
      scope.setTag('novi-transaction-id', transactionId)
    );

    if (
      ['post', 'put', 'patch'].includes(options?.method?.toLowerCase()) &&
      typeof options.body === 'object' &&
      !(options.body instanceof FormData)
    ) {
      headers['Content-Type'] = headers['Content-Type'] || 'application/json';
      options.body = JSON.stringify(options.body);
    }

    let base;
    // default is Rails API.
    if (apiBase === APIBase.RAILS) {
      base = process.env.REACT_APP_API_BASE;
      headers['novi-transaction-id'] = transactionId;
    }
    if (apiBase === APIBase.DJANGO) {
      base = process.env.REACT_APP_DJANGO_API_BASE;
    }

    const url = new URL(resource, base);

    url.search = stringify({
      ...parse(url.search),
      ...params,
    });

    const url_string = url.toString();

    if (url_string.length > 2048) {
      sentry.captureException(
        `URL length exceeded 2048 characters (${url.toString()})`
      );
    }

    const request = new Request(url_string, {
      method: 'GET',
      ...options,
      headers: {
        Authorization: `Bearer ${
          isPublicApi ? PUBLIC_TOKEN : globalToken.current
        }`,
        ...headers,
      },
      signal,
    });
    let responseData;
    let response;

    try {
      response = await fetch(request);
      responseData = await response.json();
    } catch (e) {
      raiseError(request, response, { retryTimes });
    } finally {
      if (!response?.ok) {
        raiseError(request, response, { responseData, retryTimes });
      }
      currentAbortController = null;
    }
    return responseData;
  }

  return fetcher;
}

const raiseError = (request, response, { responseData, retryTimes }) => {
  // Silently warn if fetch was aborted
  if (request?.signal?.aborted) {
    console.warn('Duplicate fetch aborted: ', request.url);
    return;
  }
  const errorMsg =
    getErrorMessageFromPayload(response, responseData) ||
    getErrorMessageFromStatus(response?.status);
  const error = new FetchError(errorMsg, request, response, {
    responseData,
    retryTimes,
  });

  throw error;
};

const getErrorMessageFromPayload = (response, responseData) => {
  if (response?.status === 500 || !responseData?.error) {
    return;
  }
  if (typeof responseData.error === 'string') {
    return responseData.error;
  }
  return responseData.error?.detail || responseData.error?.message;
};

const STATUS_MSG_MAP = {
  403: UNAUTHORIZED_MSG,
  401: UNAUTHENTICATED_MSG,
};

const getErrorMessageFromStatus = (status) => {
  return STATUS_MSG_MAP[status] || DEFAULT_ERROR_MSG;
};

const fetcher = makeFetcher();

export default fetcher;
