import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  NoContent,
  NoDataVariants,
  TabsAndTable,
  TabGroup,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableFoot,
  TableBodyCell,
  PaginationV2,
  PillColor,
  PillColorNames,
} from 'design-system/components';
import { useStatefulQueryParam } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

import { SKURequirementsResponse } from '../data/types';
import SKURequirementsRow from './SKURequirementsRow';
import styles from '../sku-requirements.module.scss';

interface TableProps {
  response: { data: SKURequirementsResponse; loading?: boolean; error?: any };
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  setSelectedRequirement: Dispatch<SetStateAction<any>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentRow: Dispatch<SetStateAction<any>>;
  setCurrentProductId: Dispatch<SetStateAction<number | null>>;
  modalNavPrevLogic: (i: number) => void;
  modalNavNextLogic: (i: number) => void;
  currentRow: number | null;
  modalOpen: boolean;
  isRetailer?: boolean;
}

const SKURequirementsTabsAndTable = ({
  response,
  setSelectedRequirement,
  setModalOpen,
  setCurrentRow,
  setCurrentProductId,
  modalNavPrevLogic,
  modalNavNextLogic,
  currentRow,
  modalOpen,
  activeTabIndex,
  setActiveTabIndex,
  isRetailer,
}: TableProps) => {
  const { data, loading, error } = response;
  const [page, setPage] = useStatefulQueryParam(UrlParam.Page);

  const columns = [
    {
      label: 'Requirement',
      value: 'requirement_id',
    },
    {
      label: 'Pillar',
      value: 'pillar',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: '',
      value: 'cta',
    },
  ];

  const tabData = useMemo(() => {
    const pendingData = {
      id: 4,
      label: 'Pending',
      quantity: data?.screeningStatusCounts?.pending ?? 0,
      pillColor: PillColor.Blue as PillColorNames,
    };
    const allData = {
      id: 0,
      label: 'All requirements',
      quantity: data?.screeningStatusCounts?.total ?? 0,
      pillColor: PillColor.Purple as PillColorNames,
    };
    const meetsData = {
      id: 1,
      label: 'Meets',
      quantity: data?.screeningStatusCounts?.pass ?? 0,
      pillColor: PillColor.Green as PillColorNames,
    };
    const doesNotMeetData = {
      id: 2,
      label: 'Does not meet',
      quantity:
        (data?.screeningStatusCounts?.fail ?? 0) +
        (data?.screeningStatusCounts?.rejected ?? 0),
      pillColor: PillColor.Red as PillColorNames,
    };
    const needsAttentionData = {
      id: 3,
      label: 'Needs attention',
      quantity:
        (data?.screeningStatusCounts?.needsInformation ?? 0) +
        (data?.screeningStatusCounts?.updating ?? 0),
      pillColor: PillColor.Orange as PillColorNames,
    };
    const notApplicableData = {
      id: 5,
      label: 'Not applicable',
      quantity: data?.screeningStatusCounts?.notApplicable ?? 0,
      pillColor: PillColor.Default as PillColorNames,
    };

    const retailerTabData = [
      pendingData,
      allData,
      meetsData,
      doesNotMeetData,
      needsAttentionData,
      notApplicableData,
    ];

    const brandTabData = [
      allData,
      meetsData,
      doesNotMeetData,
      needsAttentionData,
      pendingData,
      notApplicableData,
    ];

    return isRetailer ? retailerTabData : brandTabData;
  }, [data?.screeningStatusCounts]);
  const noContent = !data?.requirements.length && !loading && !error;
  const hasData = data?.requirements.length;
  const noContentMessage = isRetailer
    ? 'No requirements are pending your review for this SKU at this time. Explore the other tabs to review this SKU further, or go back to review additional SKUs.'
    : 'No results found at this time. If you believe this is an error please reach out to support.';
  return (
    <TabsAndTable
      tabs={
        <TabGroup
          activeTabIndex={activeTabIndex}
          ariaLabelledBy="tabs-and-filter-header-subtitle"
        >
          {tabData.map((tab, i) => (
            <Tab
              key={tab.id}
              chipValue={tab.quantity}
              onClick={() => setActiveTabIndex(i)}
              isActive={activeTabIndex === i}
              pillColor={tab.pillColor}
            >
              {tab.label}
            </Tab>
          ))}
        </TabGroup>
      }
      table={
        <>
          {loading && !error && <NoContent loading />}
          {error && (
            <NoContent variant={NoDataVariants.Default}>
              An error occurred while fetching the data. Please try again later
              or reach out to support.
            </NoContent>
          )}
          {noContent && (
            <NoContent variant={NoDataVariants.Default}>
              {noContentMessage}
            </NoContent>
          )}
          {hasData && !loading ? (
            <div className={styles.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableHeadCell key={`${column.value}-${i}`}>
                        {column.label}
                      </TableHeadCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.requirements.map((requirement, i) => (
                    <SKURequirementsRow
                      key={`${requirement.id}-${i}`}
                      requirement={requirement}
                      isActive={currentRow === i + 1}
                      modalOpen={modalOpen}
                      onClick={() => {
                        setCurrentProductId(data?.id);
                        setSelectedRequirement(requirement);
                        setModalOpen(true);
                        setCurrentRow(i + 1);
                        modalNavPrevLogic(i);
                        modalNavNextLogic(i);
                      }}
                      isRetailer={isRetailer}
                    />
                  ))}
                </TableBody>
                <TableFoot bgWhite>
                  <TableRow>
                    <TableBodyCell colSpan={columns.length}>
                      <PaginationV2
                        onNextPage={() => page && setPage && setPage(+page + 1)}
                        onPreviousPage={() =>
                          page && setPage && setPage(+page - 1)
                        }
                        onPageSelect={setPage}
                        currentPage={data?.page}
                        totalItemCount={data?.count}
                        itemsPerPage={data?.items}
                      />
                    </TableBodyCell>
                  </TableRow>
                </TableFoot>
              </Table>
            </div>
          ) : null}
        </>
      }
    />
  );
};

export default SKURequirementsTabsAndTable;
