import { ScreeningStatus } from 'design-system/data';

export const SORT_OPTIONS = {
  '': 'Default',
  'requirement_name_asc': 'Requirement: A to Z',
  'requirement_name_desc': 'Requirement: Z to A',
  'sku_asc': 'SKU: A to Z',
  'sku_desc': 'SKU: Z to A',
};

export const FILTER_OPTIONS = [
  {
    title: 'Status',
    options: [
      { value: ScreeningStatus.Approved, label: 'Accepted' },
      { value: ScreeningStatus.Rejected, label: 'Rejected' },
      { value: ScreeningStatus.Pending, label: 'Pending' },
    ],
  },
];
