import { FC, useMemo } from 'react';
import {
  NoContent,
  NoDataVariants,
  PaginationV2,
  Table,
  TableBody,
  TableBodyCell,
  TableFoot,
  TableHead,
  TableHeadCell,
  TableRow,
  TabsAndTable,
  TabGroup,
  Tab,
  SearchFilter,
  PillColor,
  PillColorNames,
} from 'design-system/components';
import { SubmissionStatusTabData } from 'design-system/types';
import { useNameSpacedQueryParam } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

import { SORT_OPTIONS } from '../constants';
import styles from '../retailerBrandSubmissions.module.scss';
import DocumentsFeedbackTableRow from './TableRow';
import {
  DocumentsFeedbackTableProps,
  DocumentFeedback,
  EndpointResponse,
} from './types';

const DocumentsFeedbackTable: FC<DocumentsFeedbackTableProps> = ({
  retailerName = 'Target',
  tableData,
  modalOpen,
  setModalOpen,
  setSelectedRequirement,
  setCurrentRow,
  modalNavPrevLogic,
  modalNavNextLogic,
  activeTabIndex,
  setActiveTabIndex,
}: DocumentsFeedbackTableProps) => {
  const { data, loading, error }: EndpointResponse = tableData || {
    data: { results: [], loading: true, error: null },
  };
  const { results: documentsFeedback, screeningStatusCounts } = data || {
    results: [],
    screeningStatusCounts: {},
  };
  const noContent = !documentsFeedback?.length && !loading && !error;

  const namespace = 'documents';

  const [currentPage, setCurrentPage] = useNameSpacedQueryParam(
    UrlParam.Page,
    namespace,
    1
  );

  const [, setStatusFilter] = useNameSpacedQueryParam<string | null>(
    'pillar_status',
    namespace,
    ''
  );

  const tabData = useMemo<SubmissionStatusTabData[]>(() => {
    return [
      {
        id: 0,
        label: 'All documents',
        value: 'all-documents',
        quantity:
          (screeningStatusCounts?.accepted || screeningStatusCounts?.pass) +
            screeningStatusCounts?.rejected +
            screeningStatusCounts?.pending || 0,
        pillColor: PillColor.Blue as PillColorNames,
      },
      {
        id: 1,
        label: 'Accepted',
        value: 'pass',
        quantity:
          screeningStatusCounts?.accepted || screeningStatusCounts?.pass || 0,
        pillColor: PillColor.Green as PillColorNames,
      },
      {
        id: 2,
        label: 'Rejected',
        value: 'rejected',
        quantity: screeningStatusCounts?.rejected || 0,
        pillColor: PillColor.Red as PillColorNames,
      },
      {
        id: 3,
        label: 'Pending',
        value: 'pending',
        quantity: screeningStatusCounts?.pending || 0,
        pillColor: PillColor.BrandSecondary as PillColorNames,
      },
    ];
  }, [screeningStatusCounts]);

  const builtFilterOptions = useMemo(() => {
    if (!data?.pillars?.length) return null;

    return [
      {
        title: 'Pillar',
        options: data?.pillars?.map(
          (pillar) =>
            ({
              label: pillar.name,
              value: pillar.id.toString(),
            } ?? [])
        ),
      },
    ];
  }, [data?.pillars]);

  return (
    <div>
      <TabsAndTable
        tabs={
          <TabGroup
            activeTabIndex={activeTabIndex}
            ariaLabelledBy="tabs-and-filter-header-subtitle"
            additionalHeader={
              <SearchFilter
                sortOptions={SORT_OPTIONS}
                filterOptions={builtFilterOptions}
                filterParamName="pillar_id"
                namespace={namespace}
              />
            }
          >
            {tabData.map((tab: SubmissionStatusTabData) => (
              <Tab
                key={tab.id}
                ariaId={tab.value}
                chipValue={tab.quantity}
                onClick={() => {
                  setActiveTabIndex(tab.id);
                  setStatusFilter(
                    tab.value === 'all-documents' ? null : tab.value || ''
                  );
                }}
                isActive={activeTabIndex === tab.id}
                pillColor={tab.pillColor}
              >
                {tab.label}
              </Tab>
            ))}
          </TabGroup>
        }
        table={
          <>
            {(loading || error) && (
              <NoContent
                variant={NoDataVariants.Default}
                loading={!error && loading}
              >
                {error && (
                  <>
                    An error occurred while fetching the data. Please try again
                    later or reach out to support.
                  </>
                )}
              </NoContent>
            )}
            {noContent && (
              <NoContent variant={NoDataVariants.Default}>
                Submit your attestations to {retailerName} to receive feedback.
              </NoContent>
            )}
            {!loading && !error && !noContent && (
              <div className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>Document</TableHeadCell>
                      <TableHeadCell>Requirement</TableHeadCell>
                      <TableHeadCell>Pillar</TableHeadCell>
                      <TableHeadCell>SKU</TableHeadCell>
                      <TableHeadCell>Status</TableHeadCell>
                      <TableHeadCell>Retailer notes</TableHeadCell>
                      <TableHeadCell>{/* View Details */}</TableHeadCell>
                      <TableHeadCell>{/* Download file */}</TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentsFeedback.map(
                      (rowData: DocumentFeedback, index: number) => (
                        <DocumentsFeedbackTableRow
                          rowData={rowData}
                          index={index}
                          onClick={() => {
                            setSelectedRequirement(rowData);
                            setModalOpen(true);
                            setCurrentRow(index + 1);
                            modalNavPrevLogic(index);
                            modalNavNextLogic(index);
                          }}
                          isActive={false}
                          modalOpen={modalOpen}
                        />
                      )
                    )}
                  </TableBody>
                  <TableFoot bgWhite>
                    <TableRow>
                      <TableBodyCell colSpan={3}>
                        <PaginationV2
                          onNextPage={() =>
                            setCurrentPage((currentPage + 1) as 1)
                          }
                          onPreviousPage={() =>
                            setCurrentPage((currentPage - 1) as 1)
                          }
                          onPageSelect={setCurrentPage}
                          currentPage={data?.page}
                          totalItemCount={data?.count}
                          itemsPerPage={data?.items}
                        />
                      </TableBodyCell>
                    </TableRow>
                  </TableFoot>
                </Table>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default DocumentsFeedbackTable;
