import useApi from 'services/api/useApi';
import { EndpointResponseRaw } from './types';
import { useNameSpacedQueryParams } from 'design-system/utils';

export const useAttestationsFeedbackData = (
  retailerId: string,
  brandId: string,
  policyId: string
): EndpointResponseRaw => {
  const params = useNameSpacedQueryParams('attestations');
  const url = `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/attestation_approvals`;
  const { data, loading, error } = useApi(url, {
    param: params[0],
    enabled: Boolean(retailerId && brandId && policyId),
  });
  return {
    data,
    loading,
    error,
  } as EndpointResponseRaw;
};
