/* eslint-disable no-unused-vars */
export interface APIEndpointRaw {
  loading: boolean;
  error: any;
}

export interface APIEndpoint {
  loading: boolean;
  error: any;
}

export interface PaginatedData {
  page: number;
  count: number;
  items: number;
}

export const APIBase = {
  DJANGO: 'django',
  RAILS: 'rails',
} as const;

export type APIBaseType = keyof typeof APIBase;

export const APIRequestMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
} as const;

export type APIRequestMethodType = keyof typeof APIRequestMethod;
