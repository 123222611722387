import { useEffect, useRef, useState } from 'react';
import {
  FileUpload,
  Icon,
  IconName,
  LoadingOverlay,
  PolicyIcon,
  Text,
  TextElement,
  TextKind,
  Toast,
  ToastKind,
} from 'design-system/components';
import { PLACEHOLDER } from 'design-system/data';
import { APIRequestMethod } from 'design-system/types';

import { makeFetcher } from 'services/api/fetcher';
import uploadFile from 'utils/uploadFile';
import SupplierModalListItem from './list-item';
import styles from './modal-contents.module.scss';

const SupplierModalContents = ({
  data,
  materialID,
  isPolicyUnsettled,
  clearDocumentData,
}) => {
  const fetcher = makeFetcher();
  const toastRef = useRef();
  const successToastData = {
    kind: ToastKind.Success,
    title: 'Success',
    msg: 'Upload successful.',
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [toastData, setToastData] = useState(successToastData);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  async function uploadDocument(files) {
    if (files) {
      setShowLoadingOverlay(true);
      try {
        const blobIds = [];
        await Promise.all(
          files.map(async (file) => blobIds.push(await uploadFile(file)))
        );
        await fetcher(`/api/v4/suppliers/materials/${materialID}/documents`, {
          method: APIRequestMethod.POST,
          body: { blob_ids: blobIds },
        });
        setToastData(successToastData);
        setUploadedFiles([...uploadedFiles, ...files]);
      } catch (e) {
        setToastData({
          kind: ToastKind.Error,
          title: 'Error',
          msg: 'Something went wrong.',
        });
      }
      setShowLoadingOverlay(false);
      toastRef.current.launchToast();
    }
  }

  useEffect(() => {
    if (uploadedFiles.length) {
      setUploadedFiles([]);
    }
    // we only want to listen for clearDocumentData changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearDocumentData]);

  return (
    <>
      <LoadingOverlay show={showLoadingOverlay} />
      <Toast ref={toastRef} kind={toastData.kind} title={toastData.title}>
        {toastData.msg}
      </Toast>
      <div className={styles.container}>
        <div className={styles.set}>
          <Text kind={TextKind.TextSMBold} element={TextElement.P}>
            Policy
          </Text>
          <div className={styles.policy}>
            <PolicyIcon src={data?.imagePath} alt={data?.name} />
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              {data?.name || PLACEHOLDER}
            </Text>
          </div>
        </div>
        <div className={styles.set}>
          <Text kind={TextKind.TextSMBold} element={TextElement.P}>
            Description
          </Text>
          <Text kind={TextKind.TextMD} element={TextElement.P}>
            {data?.description || PLACEHOLDER}
          </Text>
        </div>
        {isPolicyUnsettled &&
          data?.restrictions[0]?.additionalDocumentation && (
            <div className={styles.info}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                Document requirements
              </Text>
              {data?.restrictions?.map(
                (restriction, index) =>
                  restriction?.additionalDocumentation && (
                    <SupplierModalListItem
                      value={restriction?.additionalDocumentation}
                      index={index}
                      key="additional-documentation"
                    />
                  )
              )}
            </div>
          )}
        {data?.restrictions[0]?.restriction && (
          <div className={styles.warning}>
            <Text kind={TextKind.TextSMBold} element={TextElement.P}>
              {isPolicyUnsettled ? 'Requirements' : 'Requirements not met'}
            </Text>
            {data?.restrictions?.map(
              (restriction, index) =>
                restriction?.restriction && (
                  <SupplierModalListItem
                    value={restriction?.restriction}
                    index={index}
                    key="restriction"
                  />
                )
            )}
          </div>
        )}
        {isPolicyUnsettled && (
          <>
            {uploadedFiles?.length > 0 && (
              <div className={styles.set}>
                <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                  Uploaded documents
                </Text>
                {uploadedFiles?.map((file) => (
                  <div className={styles.file}>
                    <Icon name={IconName.Document} size={16} />
                    <Text kind={TextKind.TextSM} element={TextElement.P}>
                      {file?.name || PLACEHOLDER}
                    </Text>
                  </div>
                ))}
              </div>
            )}
            <div className={styles.set}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                File upload
              </Text>
              <FileUpload
                multiFileSupport
                onChange={uploadDocument}
                data-cy="supplier-resolve-modal-doc-upload"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SupplierModalContents;
