import { APIBase, APIRequestMethod } from 'design-system/types';
import fetcher from 'services/api/fetcher';
import useAsyncCall from 'services/api/useAsyncCall';
import { useApp } from 'context/AppContext';

export const useChargebeeCheckout = (retailerBrandId: number) => {
  const { setCheckoutUrl } = useApp();
  const asyncFn = async () => {
    const response = await fetcher(
      `/api/chargebee/retailer_consumer_brands/${retailerBrandId}/checkout_url`,
      {
        method: APIRequestMethod.GET,
        apiBase: APIBase.DJANGO,
      }
    );
    if (response.url) {
      setCheckoutUrl(response.url);
    }
  };
  return useAsyncCall(asyncFn as unknown as () => null);
};
