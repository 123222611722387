import { Document } from 'design-system/types';

export const downloadFile = (file: Document) => {
  fetch(file.path)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      // Cleanup
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
};
