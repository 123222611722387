import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastProvider } from '@radix-ui/react-toast';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import PolicySelectionPage from 'views/Brands/RetailerBrandEligibility/PolicySelectionPage';
import { RequirementActionsProvider } from '../shared/RequirementActionModalContents/context';
import SubmissionsV2View from './view';

const RetailerBrandSubmissionsV2: FC = () => {
  const { retailerBrand } = useApp();
  const link = (policyId: number) =>
    PATHS.retailerBrandSubmissionsWithId.replace(
      ':policy_id',
      policyId.toString()
    );
  return (
    <ToastProvider>
      <RequirementActionsProvider>
        <Switch>
          <Route
            path={PATHS.retailerBrandSubmissions}
            component={() => (
              <PolicySelectionPage
                breadCrumbText="Submissions"
                title="Your submissions"
                subTitle={`View that status of your submissions to the ${
                  (retailerBrand?.retailer as any)
                    ? retailerBrand.retailer.name
                    : 'retailer'
                } programs here.`}
                retailerBrand={retailerBrand}
                link={link}
              />
            )}
            secure
            exact
          />
          <Route
            path={PATHS.retailerBrandSubmissionsWithId}
            component={SubmissionsV2View}
            secure
            exact
          />
        </Switch>
      </RequirementActionsProvider>
    </ToastProvider>
  );
};

export default RetailerBrandSubmissionsV2;
