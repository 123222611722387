import { useMemo } from 'react';

import {
  BrandStatusDisplayData,
  NavDataProps,
  NavDataReturnData,
} from 'design-system/components';

import {
  PATHS,
  FDA_REGISTRATION_PATH,
  RETAILER_PATH,
  RETAILER_BRAND_PATH,
  ROLES,
} from 'constants/index';
import useUserFlow from 'hooks/useUserFlow';
import { useChargebeeCheckout } from 'utils/chargebee';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import { useUserInvitation } from 'context/UserInvitationContext';
import { useApp } from 'context/AppContext';
import useRetailer from './useRetailer';

const useNavData = ({ setHideMobileNav }: NavDataProps): NavDataReturnData => {
  const {
    auth0,
    user,
    retailerBrand: lastSelectedRetailerBrand,
    subscriptionTiers,
    selectedRetailerBrandId,
    isInMoCRAPortal,
  } = useApp();

  const isBrand = user?.hasRole(ROLES.brand);
  const isRetailer = user?.hasRole(ROLES.retailer);
  const isSupplier = user?.hasRole(ROLES.supplier);
  const isFormulator = user?.hasRole(ROLES.formulator);
  const isContractManufacturer = user?.hasRole(ROLES.contractManufacturer);
  const hasSephoraRetailerFlow = user?.hasFF('sephora_retailer_flow');
  const hasProductRequests = user?.hasFF('product_requests_tracker');
  const hasReportsPage = user?.hasFF('sephora_reports_page') && isRetailer;

  const { unlocked } = lastSelectedRetailerBrand || {};
  const hasUnpaidSubscriptions = !unlocked;
  const flow = useUserFlow();

  const {
    user: { permissions },
  } = flow;
  const legacySephora = flow.legacy_sephora_flow;
  const orgId = user?.organization.in_orgable_id;
  const canScreen = permissions?.canScreenIngredients;
  const canPurchase = permissions?.canPurchase && user?.po_count > 0;

  const retailerId = isRetailer ? orgId : null;

  const { retailer } = useRetailer(retailerId);

  const logout = () => {
    auth0?.client.logout({ returnTo: window.location.origin });
  };

  const retailerBrand = useMemo(() => {
    return isBrand && selectedRetailerBrandId
      ? { id: selectedRetailerBrandId, brandId: orgId }
      : null;
  }, [isBrand, orgId, selectedRetailerBrandId]);

  const base = useMemo(() => {
    switch (true) {
      case isInMoCRAPortal:
        return FDA_REGISTRATION_PATH;
      case Boolean(retailerBrand):
        return RETAILER_BRAND_PATH;
      case retailer:
        return RETAILER_PATH;
      default:
        return RETAILER_PATH;
    }
  }, [retailer, retailerBrand, isInMoCRAPortal]);
  const generateHref = (path: string) => `${base}/${path}`;
  const isPathActive = (path: string[] | string) => {
    if (Array.isArray(path)) {
      return path.some((path) => window.location.pathname.includes(path));
    }
    return window.location.pathname.includes(path);
  };

  // logic to support retailers, retailer brand portal
  const homeUrls = useMemo(() => {
    if (isSupplier) {
      return;
    }
    return {
      dashboard: generateHref('dashboard'),
      ...(hasReportsPage && {
        reports: generateHref('reports'),
      }),
      ...(retailerBrand && { base }),
      ...(retailerBrand && {
        pillars: generateHref('requirement_groups'),
      }),
      ...(!isRetailer && {
        skus: generateHref('skus'),
      }),
      ...(retailerBrand && {
        eligibility: generateHref('eligibility'),
      }),
      documents: generateHref('documents'),
      ...(retailerBrand && {
        submissions: generateHref('submissions'),
      }),
    };
  }, [retailerBrand, hasReportsPage, base]);

  const statusData = hasUnpaidSubscriptions
    ? BrandStatusDisplayData.Unpaid
    : BrandStatusDisplayData.Paid;

  const { analyticsWrap } = useAnalyticsEvent();
  const { openModal: openInvitationModal } = useUserInvitation();
  const hasV2OnboardingData: Boolean =
    lastSelectedRetailerBrand?.retailer &&
    selectedRetailerBrandId &&
    isBrand &&
    !isRetailer;

  const clickWrapper = ({
    label = 'navigation',
    action = '',
    callback = () => {},
  }) => {
    setHideMobileNav(true);
    return analyticsWrap(
      {
        label,
        category: 'left_nav',
        action,
      },
      callback
    )();
  };

  const marketplaceData = {
    show: !isSupplier && !isContractManufacturer,
    text: 'Marketplace',
    path: PATHS.product_marketplace,
    search: 'category_key=ingredient',
    icon: 'ShoppingCartSimple',
    onClick: () => clickWrapper({ action: 'click_menu_marketplace' }),
  };

  const sephoraData = {
    show: legacySephora,
    text: 'Sephora Planet Delta',
    path: PATHS.sephoraBrandDashboard,
    onClick: () =>
      clickWrapper({ action: 'click_menu_sephora_brand_dashboard' }),
  };

  const workbenchData = {
    show: isBrand || isFormulator || isContractManufacturer,
    active: isPathActive([
      isFormulator ? PATHS.formulatorDashboard : PATHS.projectDashboard,
      PATHS.ingredient_search,
    ]),
    text: 'Workbench',
    icon: 'Flask',
    formulation: {
      text:
        isBrand || isContractManufacturer
          ? 'Formulation Verification'
          : 'Projects',
      path: isFormulator ? PATHS.formulatorDashboard : PATHS.projectDashboard,
      onClick: () => clickWrapper({ action: 'click_menu_verify_formulas' }),
    },
    ingredientScreener: {
      show: canScreen,
      text: 'Ingredient Screener',
      path: PATHS.ingredient_search,
      onClick: () => clickWrapper({ action: 'click_menu_ingredient_screener' }),
    },
  };

  const onboardingData = {
    selectedRetailer: lastSelectedRetailerBrand?.retailer,
    invoiceLink: lastSelectedRetailerBrand?.stripe_invoice_id,
  };

  const retailerBrandsData = {
    show: isRetailer,
    text: 'Brands',
    icon: 'Flask',
    active: isPathActive([
      PATHS.retailerBrandsMetrics,
      PATHS.retailerBrandsSubmissions,
    ]),
    metrics: {
      text: 'Metrics',
      path: PATHS.retailerBrandsMetrics,
      active: isPathActive(PATHS.retailerBrandsMetrics),
      onClick: () => clickWrapper({ action: 'click_menu_brand_metrics' }),
    },
    submissions: {
      text: 'Submissions',
      path: PATHS.retailerBrandsSubmissions,
      active: isPathActive(PATHS.retailerBrandsSubmissions),
      onClick: () => clickWrapper({ action: 'click_menu_brand_submissions' }),
    },
  };

  const retailerSkusData = {
    show: isRetailer,
    text: 'SKUs',
    icon: 'Flask',
    active: isPathActive(PATHS.retailerSkuMetrics),
    metrics: {
      text: 'Metrics',
      path: PATHS.retailerSkuMetrics,
      onClick: () => clickWrapper({ action: 'click_menu_skus_metrics' }),
    },
  };

  const retailerAdditionalReports = {
    show: isRetailer && (retailer as any)?.additional_dashboards?.length > 0,
    text: 'Additional Reports',
    icon: 'Flask',
    active: isPathActive(PATHS.retailerAdditionalReports),
    additionalReports: (retailer as any)?.additional_dashboards?.map(
      (report: any) => {
        return { text: report.title, id: report.id };
      }
    ),
  };

  const moCRAPortalData = {
    show: isInMoCRAPortal,
    base: {
      text: 'MoCRA',
      path: PATHS.base,
      // TODO: Implement Anayltics Tracking for MoCRAPortal Menu Clicks
      // https://linear.app/novi/issue/ENG-4944/
      onClick: () => setHideMobileNav(true),
    },
    dashboard: {
      text: 'Dashboard',
      onClick: () => {
        setHideMobileNav(true);
      },
    },
    skus: {
      text: 'SKUs',
      onClick: () => setHideMobileNav(true),
    },
    submissions: {
      text: 'Submissions',
      onClick: () => setHideMobileNav(true),
    },
  };

  const supplierData = {
    show: isSupplier,
    listings: {
      text: 'Listings',
      icon: 'Tag',
      path: PATHS.supplierListings,
      onClick: () => clickWrapper({ action: 'click_supplier_listings' }),
    },
  };

  const formulatorData = {
    show: isFormulator,
    purchaseOrders: {
      show: canPurchase,
      text: 'Purchase Orders',
      icon: 'Receipt',
      path: PATHS.purchaseOrders,
      onClick: () => clickWrapper({ action: 'click_menu_purchase_order' }),
    },
    productRequests: {
      show: hasProductRequests && canPurchase,
      text: 'Product Requests',
      icon: 'Tray',
      path: PATHS.ingredientRequests,
      onClick: () => clickWrapper({ action: 'click_menu_product_requests' }),
    },
  };

  const contractManufacturerData = {
    show: isContractManufacturer,
    home: {
      text: 'Home',
      path: PATHS.base,
      icon: 'House',
      onClick: () => clickWrapper({ action: 'click_menu_home' }),
    },
    sephoraBrandResults: {
      show: hasSephoraRetailerFlow,
      text: 'Sephora Brand Results',
      onClick: () => clickWrapper({ action: 'click_menu_sephora_brands' }),
    },
  };

  const inviteModalData = {
    show: isFormulator || isContractManufacturer,
    text: 'Invite your Team',
    onClick: () =>
      clickWrapper({
        label: 'user_invitation_modal',
        action: 'click_user_invitation_cta',
        callback: openInvitationModal,
      }),
  };

  const selectedRetailer = onboardingData?.selectedRetailer || retailer;
  const supportEmail =
    selectedRetailer?.support_email || 'support@noviconnect.com';

  const checkoutHook = useChargebeeCheckout(lastSelectedRetailerBrand?.id);
  // TODO: Cleanup when fully migrated to chargebee (FF: tmp_chargebee_paywall).
  const handleCheckout = user?.hasFF('tmp_chargebee_paywall')
    ? checkoutHook
    : null;

  const navData = {
    ...(hasV2OnboardingData ? onboardingData : {}),
    moCRAPortalData,
    workbenchData,
    formulatorData,
    sephoraData,
    retailerBrandsData,
    retailerSkusData,
    supplierData,
    contractManufacturerData,
    marketplaceData,
    inviteModalData,
    selectedRetailerBrandId,
    homeUrls,
    supportEmail,
    statusData,
    logout,
    isPathActive,
    generateHref,
    base,
    selectedRetailer,
    isInMoCRAPortal,
    displayRequestToPay: !unlocked,
    hasUnpaidSubscriptions,
    username: user?.nickname,
    email: user?.email,
    isBrand: isBrand,
    retailerAdditionalReports,
    currentTierId: lastSelectedRetailerBrand?.current_tier_id,
    subscriptionTiers,
    handleCheckout,
  };

  return navData;
};

export default useNavData;
