import { useEffect } from 'react';
import { Modal, ModalType } from 'design-system/components';

import { useApp } from 'context/AppContext';
import styles from './checkout-modal.module.scss';

// We ultimately don't own the iframe content, so if chargebee changes..
// .. our implementation can become out of sync.
const CheckoutModal = () => {
  const { checkoutUrl, setCheckoutUrl } = useApp();

  // Scroll iframe to top on load without making cors sad.
  useEffect(() => {
    if (checkoutUrl) {
      const modal = document.getElementById('checkout-modal');
      const modalContent = modal?.querySelector('[data-cy="modal-content"]');
      const iframe = document.getElementById('checkout-iframe');
      iframe?.addEventListener('load', () => {
        if (modalContent) {
          // use hardcoded value b/c measured distance is variable with state.
          modalContent.scrollTop = 158;
        }
      });
    }
  }, [checkoutUrl]);

  return (
    <Modal
      show={Boolean(checkoutUrl)}
      onClick={() => setCheckoutUrl(undefined)}
      type={ModalType.ExtraLarge}
      confirmClose
      id="checkout-modal"
    >
      <div className={styles.container} id="checkout-iframe-container">
        <iframe
          id="checkout-iframe"
          src={checkoutUrl}
          title="Chargebee Checkout"
          allow="payment"
          loading="lazy"
        />
      </div>
    </Modal>
  );
};

export default CheckoutModal;
