import { useMemo } from 'react';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import propTypes from 'prop-types';

import { PhosphorIcon } from 'design-system/components';
import { LinkErrorMsg, LinkKind, LinkVariant, LinkSize } from './constants';
import styles from './link.module.scss';

function Link({ suppressDefaultIcon = false, ...props }) {
  const variant = props.variant || LinkVariant.Default;

  const children = useMemo(() => {
    if (variant === LinkVariant.NewDefault && !suppressDefaultIcon) {
      return (
        <>
          {props.children}
          <span className={styles['icon-container']}>
            <PhosphorIcon
              forceLoading={props.loading}
              iconName="ArrowRight"
              size="1.25rem"
            />
          </span>
        </>
      );
    }
    return props.children;
  }, [props.children, variant, suppressDefaultIcon, props.loading]);

  const classes = cn([
    props.disabled ? styles.disabled : styles[variant],
    props.size ? styles[props.size] : null,
    ...([props.className] || []),
  ]);

  if (Object.values(LinkKind).includes(props.kind)) {
    if (props.kind === LinkKind.External) {
      if (props.href) {
        return (
          <a
            className={classes}
            href={props.href}
            style={props.style}
            target="_blank"
            rel="noreferrer"
            data-cy={props['data-cy']}
            onClick={props.onClick}
          >
            {children}
          </a>
        );
      }
      throw new Error(LinkErrorMsg.HREF);
    }
    if (props.kind === LinkKind.Internal) {
      if (props.to) {
        return (
          <RouterLink
            className={classes}
            style={props.style}
            to={props.to}
            state={props.state}
            replace={props.replace}
            data-cy={props['data-cy']}
            onClick={props.onClick}
            disabled={props.disabled}
            target={props.target}
          >
            {children}
          </RouterLink>
        );
      }

      throw new Error(LinkErrorMsg.To);
    }
    throw new Error(LinkErrorMsg.LinkKind);
  }
  return null;
}

Link.propTypes = {
  kind: propTypes.oneOf(Object.values(LinkKind)).isRequired,
  size: propTypes.oneOf(Object.values(LinkSize)),
  variant: propTypes.oneOf(Object.values(LinkVariant)),
  href: propTypes.string,
  to: propTypes.string,
  state: propTypes.any,
  replace: propTypes.bool,
  children: propTypes.any.isRequired,
  [`data-cy`]: propTypes.string,
  style: propTypes.object, // escape hatch for styling.
  onClick: propTypes.func,
  disabled: propTypes.bool,
  target: propTypes.string,
  suppressDefaultIcon: propTypes.bool,
  className: propTypes.string,
  loading: propTypes.bool,
};

export default Link;
