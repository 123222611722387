import { useState, FC } from 'react';
import cn from 'classnames';

import {
  InputV2,
  FilterDropdown,
  DropdownRadioMenu,
  Text,
  TextElement,
  TextKind,
  PhosphorIcon,
} from 'design-system/components';
import {
  useStatefulQueryParam,
  useNameSpacedQueryParam,
} from 'design-system/utils';
import { UrlParam, Color } from 'design-system/data';

import styles from './search-filter.module.scss';
import { AccordionOptionGroup } from '../filter-dropdown';

export type SearchFilterProps = {
  filterOptions?: AccordionOptionGroup[] | null;
  filterParamName?: string;
  sortOptions?: Record<string, string>;
  useNameSpacedParams?: boolean;
  namespace?: string;
};

const SearchFilter: FC<SearchFilterProps> = ({
  filterOptions,
  filterParamName = UrlParam.Status,
  sortOptions,
  namespace = '',
}: SearchFilterProps) => {
  const nameSpacedParamsEnabled = Boolean(namespace);
  const params = new URLSearchParams();

  const [search, setSearch] = useStatefulQueryParam(
    UrlParam.Search,
    params.get(UrlParam.Search) || '',
    !nameSpacedParamsEnabled
  );
  const [nameSpacedSearch, setNameSpacedSearch] = useNameSpacedQueryParam(
    UrlParam.Search,
    namespace,
    params.get(UrlParam.Search) || '',
    nameSpacedParamsEnabled
  );

  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    params.get(UrlParam.Direction) || '',
    !nameSpacedParamsEnabled
  );
  const [nameSpacedSort, setNameSpacedSort] = useNameSpacedQueryParam(
    UrlParam.Direction,
    namespace,
    params.get(UrlParam.Direction) || '',
    nameSpacedParamsEnabled
  );

  const [statusFilterParams, setStatusFilterParams] = useStatefulQueryParam(
    filterParamName,
    params.get(UrlParam.Status) || ([] as Array<string>),
    !nameSpacedParamsEnabled
  );
  const [nameSpacedStatusFilterParams, setNameSpacedStatusFilterParams] =
    useNameSpacedQueryParam(
      filterParamName,
      namespace,
      params.get(UrlParam.Status) || ([] as Array<string>),
      nameSpacedParamsEnabled
    );

  const [error] = useState<string>('');
  const handleSearch = (e: any) => {
    e.preventDefault();
    nameSpacedParamsEnabled
      ? setNameSpacedSearch(e.target.value)
      : setSearch(e.target.value);
  };
  const handleActiveSort = (value: string) => {
    nameSpacedParamsEnabled ? setNameSpacedSort(value) : setActiveSort(value);
  };
  const handleFilterChange = (values: string | string[]) => {
    nameSpacedParamsEnabled
      ? setNameSpacedStatusFilterParams(values)
      : setStatusFilterParams(values);
  };

  const computedSearch = nameSpacedParamsEnabled ? nameSpacedSearch : search;
  const computedSort = nameSpacedParamsEnabled ? nameSpacedSort : activeSort;
  const computedFilterParams = nameSpacedParamsEnabled
    ? nameSpacedStatusFilterParams
    : statusFilterParams;
  return (
    <div role="search" className={cn(styles['search-filter'])}>
      <InputV2
        type="text"
        name="search"
        value={computedSearch}
        placeholder="Search"
        handleInputChange={handleSearch}
        errorMessage={error}
        hasError={error.length > 0}
      />
      <div className={cn(styles['drop-down-menus'])}>
        {filterOptions && (
          <div>
            <FilterDropdown
              accordionOptions={filterOptions}
              selectedValues={computedFilterParams as string[]}
              setSelectedValues={handleFilterChange}
            />
          </div>
        )}
        {sortOptions && (
          <div>
            <DropdownRadioMenu
              fromLeft={true}
              options={sortOptions || {}}
              reverse
              activeValue={computedSort}
              setActiveValue={handleActiveSort}
              triggerElement={
                <div className={styles['trigger-container']}>
                  <PhosphorIcon
                    iconName="ArrowsDownUp"
                    title="sort-by"
                    size={20}
                  />
                  <Text
                    kind={TextKind.TextSMSemibold}
                    element={TextElement.Span}
                    color={Color.Neutral600}
                    className={styles.nowrap}
                  >
                    {activeSort && sortOptions
                      ? `Sort by: ${sortOptions[activeSort]}`
                      : 'Sort by'}
                  </Text>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchFilter;
