import { Dispatch, SetStateAction, useEffect, useState, FC } from 'react';
import { Text, TextElement, TextKind } from 'design-system/components';
import { Color, PolicyRequirementType } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';

import styles from '../retailerBrandSubmissions.module.scss';
import Table from './Table';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import { useDocumentsFeedbackData } from './useData';
import SlideoutModal from 'views/Brands/RetailerBrandPolicyRequirements/components/SlideoutModal';
import BrandRequirementNote from 'views/Brands/RetailerBrandEligibility/SKURequirementsPage/components/BrandRequirementNote';

type DocumentsFeedbackTableProps = {
  retailerId: string;
  brandId: string;
  policyId: string;
};
const DocumentsFeedbackTable: FC<DocumentsFeedbackTableProps> = ({
  retailerId,
  brandId,
  policyId,
}) => {
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);
  const convertedData = convertSnakeToCamelCase(
    useDocumentsFeedbackData(retailerId, brandId, policyId)
  );
  const currentFilteredData = convertedData.results;

  const {
    setModalOpen,
    modalOpen,
    setSelectedRequirement,
    setCurrentProductId,
    selectedRequirement,
  } = useRequirementActions();

  useEffect(() => {
    // selectedRequirement is un-nullable, so setting current product id to null on mount instead to prevent actions api from being called.
    // prioritizing keeping context logic more stable.
    (setCurrentProductId as Dispatch<SetStateAction<any>>)(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalNavNextLogic = (i: number): void => {
    setNextActive(i < currentFilteredData?.length - 1);
  };

  const modalNavPrevLogic = (i: number): void => {
    setPreviousActive(i > 0);
  };
  return (
    <>
      <SlideoutModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        currentFilteredData={currentFilteredData}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        previousActive={previousActive}
        nextActive={nextActive}
        totalItemCount={currentFilteredData?.length}
        expectedRequirementType={undefined}
        hideSubmit={
          (selectedRequirement as any)?.requirementType ===
          PolicyRequirementType.Brand
        }
        hideSubmitNote={<BrandRequirementNote />}
        isRetailer={false}
      />
      <div className={styles['section-header']}>
        <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
          Retailer Feedback on Document Uploads
        </Text>
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Neutral600}
        >
          Some document uploads will result in a "pending" status. When you
          submit your attestations, the retailer will either accept or reject
          documents that were in the pending status.
        </Text>
      </div>
      <Table
        setSelectedRequirement={setSelectedRequirement}
        setModalOpen={setModalOpen}
        setCurrentRow={setCurrentRow}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        modalOpen={modalOpen}
        tableData={convertedData}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </>
  );
};

export default DocumentsFeedbackTable;
