import { Currencies } from 'design-system/data';
export const SAMPLE_PRICE_IN_CENTS = 100;
const getPriceCents = (noviPrice) => {
  return noviPrice.price_currency === Currencies.USD
    ? noviPrice.unit_price * 100
    : noviPrice.unit_price;
};

export const getPriceFromQuantity = (prices, quantity, isSample) => {
  if (isSample) {
    return {
      unitPriceCents: SAMPLE_PRICE_IN_CENTS,
    };
  }

  if (!prices || prices.length === 0) {
    return {
      unitPriceCents: null,
    };
  }

  const price =
    (prices.filter((x) => x.moq).sort((a, b) => a.moq - b.moq) || []).findLast(
      (p) => p.moq <= quantity
    ) || prices[0];

  const unitPriceCents = getPriceCents(price);

  return {
    ...price,
    unitPriceCents,
  };
};

export const getTotal = (items) =>
  items?.reduce((total, item) => {
    if (item.type === 'sample') {
      return total + SAMPLE_PRICE_IN_CENTS;
    }

    const { unitPriceCents } = getPriceFromQuantity(
      item.available_prices,
      item.quantity
    );

    return total + item.quantity * (unitPriceCents || item.total_price_cents);
  }, 0);
