import { createRef, useEffect } from 'react';
import {
  TableBodyCell,
  TableRow,
  Pill,
  PillColor,
  Button,
  ButtonKind,
  ButtonSize,
  PhosphorIcon,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';

import styles from '../retailerBrandSubmissions.module.scss';
import { RequirementResult } from 'design-system/types';

interface RequirementRowProps {
  result: RequirementResult;
  onClick: () => void;
  isActive: boolean;
  modalOpen: boolean;
}

const AttestationsFeedbackRow = ({
  result,
  onClick,
  isActive,
  modalOpen,
}: RequirementRowProps) => {
  const buttonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [buttonRef, isActive, modalOpen]);

  return (
    <TableRow onClick={onClick} className={isActive ? styles.active : ''}>
      <TableBodyCell>{result.requirementName}</TableBodyCell>
      <TableBodyCell>
        <Pill color={PillColor.Default}>{result.pillarName}</Pill>
      </TableBodyCell>
      <TableBodyCell>{result.skuName}</TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={result.requirementStatus} />
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Button
            kind={ButtonKind.Alternate}
            size={ButtonSize.Small}
            onClick={onClick}
            refProp={buttonRef}
          >
            <div className={styles.cta}>
              View details
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={18} />
              </span>
            </div>
          </Button>
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default AttestationsFeedbackRow;
